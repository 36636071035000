<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <student-waiting-datatable/>
      </div>
    </div>
  </div>
</template>

<script>
  import StudentWaitingDatatable from "./datatables/StudentWaitingDatatable";

  export default {
    name: "IndexWaitingStudentsPage",
    components: {StudentWaitingDatatable}
  }
</script>

<style scoped lang="scss">

</style>
