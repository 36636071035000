<template>
  <octo-table
    show-filters
    :action="endpoints.DATATABLES.studentsInProgress"
    :fields="fields"
    :filters="filters"
    :name="name"
    enable-export
    :order-by-items="['date_in', 'first_contact', 'second_contact']"
    @onResetFilters="filters = {}"
  >
    <template v-slot:student="data">
      <div class="text-truncate text-uppercase">{{ data.row.registry | optional('surname') }}
        {{ data.row.registry | optional('name') }}
      </div>
    </template>

    <template v-slot:course="data">
      <el-tooltip :content="courses[data.row.course_id] | optional('title')" :open-delay="300" placement="top">
        <label-theme-component>{{ courses[data.row.course_id] | optional('code') }}</label-theme-component>
      </el-tooltip>
    </template>

    <template v-slot:date_in="data">
      <div class="small">
        {{ data.row.date_in | date }}
      </div>
    </template>

    <template v-slot:classroom="data">
      <div class="small">{{ data.row | optional('classrooms.0.code') }}</div>
    </template>

    <template v-slot:type="data">
      <badge :type="courses[data.row.course_id].type | courseTypeColor" class="text-uppercase">
        {{ $t('common.' + courses[data.row.course_id].type) }}
      </badge>
    </template>

    <template v-slot:status="data">
      <badge :type="data.row.status | studentStatusColor" class="text-uppercase">
        {{ $t('didactics.student_status.' + data.row.status) }}
      </badge>
    </template>

    <template v-slot:deal_status="data">
      <badge
        v-if="data.row.deal && data.row.deal.status"
        :style="{backgroundColor: schedulerService.getDealStatusColor(data.row.deal.status)}"
        class="text-uppercase"
      >
        {{ $t('deal.status.' + data.row.deal.status) }}
      </badge>
      <div v-if="!data.row.deal" class="text-uppercase small">
        {{ $t('common.missing_deal') }}
      </div>
    </template>

    <template v-slot:accounting_status="data">
      <badge
        v-if="data.row.deal && data.row.deal.accounting_status"
        :type="dealAccountingStatus[data.row.deal.accounting_status].color"
        class="text-uppercase"
      >
        {{ $t('deal.accounting_statuses.' + data.row.deal.accounting_status) }}
      </badge>
    </template>

    <template v-slot:schedule_preferences="data">
      <div class="d-flex justify-content-center">
        <student-schedule-preferences :preferences="data.row.studentSchedulePreferences"/>
      </div>
    </template>

    <template v-slot:first_contact="data">
      <div class="small">
        {{ data.row.first_contact | date }}
      </div>
    </template>

    <template v-slot:second_contact="data">
      <div class="small">
        {{ data.row.second_contact | date }}
      </div>
    </template>

    <template v-slot:is_new="data">
      <octo-icon :icon="data.row.is_new ? 'checked' : 'wrong'"/>
    </template>

    <template v-slot:starting_preference="data">
      <div class="small">
        {{ data.row.starting_preference }}
      </div>
    </template>

    <template v-slot:location="data">
      <div class="text-uppercase">{{ getLocationAlias(data.row.location_id) }}</div>
    </template>

    <template v-slot:enabled_certifications="data">
      <div class="small">
        {{ getEnabledCertificationsCodes(data.row.registry) }}
      </div>
    </template>

    <template v-slot:accounting_approval="data">
      <octo-icon :icon="data.row.accounting_approval ? 'checked' : 'wrong'"/>
    </template>

    <template v-slot:individual_course="data">
      <div class="text-uppercase">
        {{ $options.filters.optional(data.row, 'deal.individual_course') ? $t('common.yes') : $t('common.no') }}
      </div>
    </template>

    <template v-slot:weekly_frequency="data">
      <div class="text-uppercase">{{ data.row | optional('deal.weekly_frequency') }}</div>
    </template>

    <template v-slot:actions="data">
      <el-tooltip :content="$t('common.open')" :open-delay="300" placement="top">
        <base-button
          class="mx-1"
          @click="$router.push({name: 'students.waiting.show', params: {id : data.row.id }})"
          size="sm"
          link
          icon
        >
          <octo-icon icon="right-arrow"/>
        </base-button>
      </el-tooltip>
    </template>

    <template slot="custom-filters">
      <div class="col-6">
        <base-input :label="$t('fields.courses')">
          <el-select
            multiple
            filterable
            collapse-tags
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.courseIds"
          >
            <el-option
              v-for="course in courses"
              class="select-default text-uppercase"
              :value="course.id"
              :label="course.code + ': ' + course.title"
              :key="course.id"
            />
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-6">
        <base-input :label="$t('common.enabled_certifications')">
          <el-select
            multiple
            filterable
            collapse-tags
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.enabledCertificationIds"
          >
            <el-option
              v-for="certificate in certificates"
              class="select-default text-uppercase"
              :value="certificate.id"
              :label="certificate.code + ': ' + certificate.title"
              :key="certificate.id"
            />
          </el-select>
        </base-input>
      </div>

      <div class="col-12 col-md-3">
        <base-input :label="$t('datatable.type')">
          <el-select
            multiple
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.courseTypes"
          >
            <el-option
              v-for="courseType in courseTypes"
              class="select-default text-uppercase"
              :value="courseType"
              :label="$t('common.' + courseType)"
              :key="courseType"
            />
          </el-select>
        </base-input>
      </div>

      <div class="col-12 col-md-3">
        <base-input :label="$t('fields.preferred_location')">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.locationIds"
          >
            <el-option
              class="select-default text-uppercase"
              v-for="(location, index) in locations"
              :value="location.id"
              :label="location.alias"
              :key="`location-${index}`"
            />
          </el-select>
        </base-input>
      </div>

      <div class="col-12 col-md-3">
        <base-input :label="$t('common.status')">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.statuses"
          >
            <el-option
              v-for="studentStatus in studentStatuses"
              class="select-default text-uppercase"
              :value="studentStatus"
              :label="$t('didactics.student_status.' + studentStatus)"
              :key="studentStatus"
            />
          </el-select>
        </base-input>
      </div>

      <div class="col-12 col-md-3">
        <base-input :label="$t('common.accounting_approval')">
          <el-select
            clearable
            class="select-default text-uppercase"
            v-model="filters.showAccountingApproval"
          >
            <el-option
              v-for="option in [
                  { value: true, label: $t('common.yes')},
                  { value: false, label: $t('common.no')},
              ]"
              class="select-default text-uppercase"
              :value="option.value"
              :label="option.label"
              :key="option.value"
            />
          </el-select>
        </base-input>
      </div>



      <div class="col-12 col-md-3">
        <base-input :label="$t('didactics.student_contacts')">
          <el-select
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.contactType"
          >
            <el-option
              v-for="contactType in contactOptions"
              class="select-default text-uppercase"
              :value="contactType"
              :label="$t('didactics.student_contact_type.' + contactType)"
              :key="contactType"
            />
          </el-select>
        </base-input>
      </div>




      <div class="col-12 col-md-3">
        <base-input :label="$t('fields.weekly_frequency')">
          <el-select
            multiple
            filterable
            clearable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.weeklyFrequencies"
          >
            <el-option
              v-for="(frequency, index) in dealWeeklyFrequencies"
              class="select-default text-uppercase"
              :value="frequency"
              :label="frequency"
              :key="`deal-frequency-${index}`"
            />
          </el-select>
        </base-input>
      </div>

      <div class="col-12 col-md-3">
        <base-input :label="$t('fields.is_new')">
          <el-select
            filterable
            clearable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.is_new"
          >
            <el-option
              v-for="option in [
                  { value: 'only_new', label: $t('fields.only_new')},
                  { value: 'exclude_new', label: $t('fields.exclude_new')},
              ]"
              class="select-default text-uppercase"
              :value="option.value"
              :label="option.label"
              :key="option.value"
            />
          </el-select>
        </base-input>
      </div>

      <div class="col-12 col-md-3">
        <base-input :label="$t('fields.individual_course')">
          <el-select
            filterable
            clearable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.individualCourse"
          >
            <el-option
              v-for="(option, index) in [
                  { value: true, label: $t('common.yes')},
                  { value: false, label: $t('common.no')},
                ]"
              class="select-default text-uppercase"
              :value="option.value"
              :label="option.label"
              :key="`individual-course-${index}`"
            />
          </el-select>
        </base-input>
      </div>
    </template>
  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Badge from "@/components/Badge";
import {mapGetters} from "vuex";
import {Select, Option} from "element-ui";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import StudentSchedulePreferences from "@/pages/Students/components/StudentSchedulePreferences";
import StudentStatuses from "@/pages/Students/resources/studentStatuses";
import StudentContactTypes from "@/pages/Students/resources/studentContactTypes";
import ClassroomTypes from "@/pages/Didactics/resources/classroomTypes";
import DealWeeklyFrequencies from "@/pages/Deals/resources/dealWeeklyFrequencies";
import DealAccountingStatus from "@/pages/Deals/resources/dealAccountingStatus";
import {schedulerService} from "@/util/scheduler-service";

export default {
  name: "StudentWaitingDatatable",
  components: {
    StudentSchedulePreferences,
    LabelThemeComponent,
    Badge,
    OctoIcon,
    OctoTable,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      name: 'StudentWaitingDatatable',
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'student', label: 'student', slot: true, align: 'left', fixed: true, width: 200},
        {prop: 'date_in', label: 'date_in', slot: true, width: 130, align: 'center'},
        {prop: 'course', label: 'course', slot: true, align: 'center', width: 150},
        {prop: 'classroom', label: 'classroom', slot: true, align: 'left', width: 200},
        {prop: 'type', label: 'type', slot: true, width: 150, align: 'center'},
        {prop: 'status', label: 'status', slot: true, width: 120, align: 'center'},
        {prop: 'accounting_status', label: 'accounting_status', width: 200, slot: true, align: 'center'},
        {prop: 'is_new', label: 'is_new', width: 80, slot: true, align: 'center'},
        {prop: 'first_contact', label: 'first_contact', width: 160, slot: true, align: 'center'},
        {prop: 'second_contact', label: 'second_contact', width: 160, slot: true, align: 'center'},
        {prop: 'starting_preference', label: 'starting_preference', width: 200, slot: true, align: 'left'},
        {prop: 'schedule_preferences', label: 'schedule_preferences', minWidth: 360, slot: true, align: 'center'},
        {prop: 'location', label: 'preferred_location', width: 200, slot: true, align: 'left'},
        {prop: 'accounting_approval', label: 'accounting_approval', width: 250, slot: true, align: 'center'},
        {prop: 'enabled_certifications', label: 'enabled_certifications', width: 250, slot: true, align: 'center'},
        {prop: 'weekly_frequency', label: 'weekly_frequency', slot: true, width: 200, align: 'center'},
        {prop: 'individual_course', label: 'individual_course', slot: true, width: 150, align: 'center'},
        {prop: 'deal_status', label: 'deal_status', width: 200, slot: true, align: 'center'},
        {prop: 'actions', label: 'actions', width: 60, slot: true, align: 'center', fixed: 'right'},
      ],
      studentStatuses: [
        StudentStatuses.pending.value,
        StudentStatuses.joined.value,
      ],
      courseTypes: [
        ClassroomTypes.course.value,
        ClassroomTypes.certificate.value,
        ClassroomTypes.workshop.value,
      ],
      contactOptions: StudentContactTypes,
      dealWeeklyFrequencies: DealWeeklyFrequencies,
      dealAccountingStatus: DealAccountingStatus,
      schedulerService: schedulerService,
    }
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
  },
  computed: {
    ...mapGetters({
      courses: 'common/allCourses',
      certificates: 'common/certificates',
      getFilters: 'common/datatableFilters',
      locations: 'common/locations'
    }),
  },
  methods: {
    getEnabledCertificationsCodes(registry) {
      return this.$_.map(registry?.enabled_certifications, certification => this.courses[certification.id].code)
        .join(', ');
    },

    getLocationAlias(locationId) {
      return this.$_.find(this.locations, location => location.id === locationId)?.alias;
    }
  }
}
</script>

<style scoped>

</style>
